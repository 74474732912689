












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.seeArea {
  .el-radio-group {
    display: flex;
    flex-direction: column;

    .el-radio :last-child {
      margin-top: 1rem;
    }
  }
}

.searchbox {
  padding: 0;
}

.cascader {
  position: relative;
  width: 100%;
  z-index: 10;
}

.tp {
  width: 50%;
  float: left;

  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }

  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;

    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}

.form-box {
  padding: 10px 20px;

  .form {
    min-width: 600px;
    padding: 20px;
  }

  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}

.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
